<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  export let buttonObj: Array<Array<string>>;

  function sendClick(key: string) {
    dispatch('buttonClick', {
      key: key,
    });
  }
</script>

<div class="flex flex-col gap-5">
  {#each buttonObj as [text, key]}
    <button
      class="border border-solid border-white text-white text-2xl"
      on:click={() => {
        sendClick(key);
      }}
    >
      {text}
    </button>
  {/each}
</div>
